import React, { ReactElement } from 'react'

interface Props {
    
}

function Divider({}: Props): ReactElement {
    return (
        <div style={{height: '2rem',background: 'var(--text-primary)'}}>
            
        </div>
    )
}

export default Divider
