import { ButtonBase, ClickAwayListener, Popover, Popper } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import clsx from 'clsx';
import React, { MouseEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import Hide from '../Hide.mole';
import './drop-down.style.scss';

export interface OptionItemType {
    image?: string,
    label: string,
    key?: string
}

interface Props {
    defaultOption: OptionItemType,
    options: OptionItemType[],
    style?: React.CSSProperties,
    btnStyle?: React.CSSProperties,
    onClick?: (arg: OptionItemType)=>void
}

function DropDown({ defaultOption, options, style, btnStyle,onClick }: Props): ReactElement {

    const [anchorEl, setAnchorEL] = useState<HTMLDivElement | null>(null);
    const [active, setActive] = useState(defaultOption);
    const popperRef = useRef<HTMLDivElement | null>(null);
    const currentElRef = useRef<HTMLButtonElement | null>(null);
    const optionsContainerRef = useRef<HTMLDivElement | null>(null);


    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        if (anchorEl) {
            setAnchorEL(null)
        } else {
            setAnchorEL(event.currentTarget);
        }
    }

    const handleOpenForce = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEL(event.currentTarget);
    }

    const handleState = () => {

    }

    useEffect(() => {
        handleRef();
    }, [anchorEl]);

    const handleRef = () => {
        setTimeout(() => {
            if (popperRef.current) {
                popperRef.current.style.willChange = 'auto'
            }
        }, 50);
    }

    const handleItemClick = (selected: OptionItemType) => {
        setAnchorEL(null);
        setActive(selected);
        onClick && onClick(selected);
    }


    return (
        <ClickAwayListener onClickAway={() => setAnchorEL(null)}>
            <div className="dropdown" style={{ zIndex: 99, ...style }}>
                <ButtonBase style={{ width: '100%', borderRadius: '1rem', ...btnStyle }} >
                    <div className="dropdown__btn" onClick={handleOpen} onMouseEnter={handleOpenForce} >
                        <Hide open={Boolean(active.image)}>
                            <img className="W(3rem) H(2rem) Mend(1rem) Pe(n)" src={active.image} alt="" />
                        </Hide>
                        <p className="subtitle-2 flex-1 Pe(n)">{active.label}</p>
                        <ExpandMore className="icon Pe(n)" />
                    </div>
                </ButtonBase>
                <Popper style={{zIndex: 99}} ref={popperRef} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-end">

                    <div className="dropdown__items Mt(3rem)" onMouseLeave={()=>setAnchorEL(null)}>
                        {options.map((item, i) =>
                            <ButtonBase key={item.key} onClick={(event: MouseEvent<HTMLButtonElement>) => handleItemClick(item)} >
                                <div className={clsx('dropdown__item D(f)', active.key === item.key && 'active')} >
                                    <Hide open={Boolean(active.image)}>
                                        <img className="W(3rem) H(2rem) Mend(1rem)" src={item.image} alt="" />
                                    </Hide>
                                    <p>{item.label}</p>
                                </div>
                            </ButtonBase>
                        )}
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    )
}

export default DropDown
