import { ButtonBase, IconButton } from '@material-ui/core';
import { Call, ExpandLess } from '@material-ui/icons';
import React, { ReactElement } from 'react';
import AppLogo from '../../molecules/app-logo/AppLogo.mole';
import SocialIcons from '../social-icons/SocialIcons.component';
import './footer.style.scss';
import Zlogo from '../../assets/images/z-logo.png';
import useSize from '../../hooks/size.hook';
import { useTranslation } from "react-i18next";

interface Props {

}

function Footer({ }: Props): ReactElement {

    const width = useSize();
    const { t } = useTranslation();

    return (
        <footer className="footer-section C(#fff)">
            <div className="footer-content">
                <div className="logo-container">
                    <div className="logo-wraper">
                        <AppLogo color="#fff" />
                    </div>

                    <div className="">
                        <ButtonBase className="Bdrs(1rem)! W(65%) Mt(2rem)!" >
                            <a href="tel:+212700122123" className="Bgc(#330d5c) Bgc(#4b108a):h P(1rem) Trs(trs-d) Bdrs(1rem) W(100%)" >
                                <div className="D(f) Ai(c)">
                                    {/* <Call className="Fz(2.5rem)!" /> */}
                                    <p className="Fz(1.4rem) Ta(start) Fw(600) C(#a29aa9)">{t("Call us")}</p>
                                </div>
                                <p className="Fz(1.8rem) Fw(600) Ta(start)">+212 700 122 123</p>
                            </a>
                        </ButtonBase>

                        <ButtonBase className="Bdrs(1rem)! W(65%) Mt(1rem)!" >
                            <div className="Bgc(#330d5c) Bgc(#4b108a):h P(1rem) Trs(trs-d) Bdrs(1rem) W(100%)" >
                                <div className="D(f) Ai(c)">
                                    {/* <Call className="Fz(2.5rem)!" /> */}
                                    <p className="Fz(1.4rem) Ta(start) Fw(600) C(#a29aa9)">{t("Contact Us")}</p>
                                </div>
                                <a href="mailto:contact@bridgetv.africa">
                                    <p className="Fz(1.8rem) Fw(600)">Contact@bridgtv.africa</p>
                                </a>
                            </div>
                        </ButtonBase>
                    </div>


                </div>
                <div className="D(f)">
                    <div className="menu link-container">
                        <p className="title">{t("Menu")}</p>
                        <a href="#home">{t("Home")}</a>
                        <a href="#features">{t("Features")}</a>
                        <a href="#why-us">{t("Why US")}?</a>
                        <a href="#download">{t("Download")}</a>
                        <a href="#price">{t("Price")}</a>
                    </div>

                    <div className="information link-container Mstart(5rem) C(#a29aa9)">
                        <p className="title">{t("Information")}</p>
                        <p className="Fz(1.4rem) Fw(600) Mt(2.5rem)" >
                            Conrad Tower - Sheikh Zayed Rd <br /> Jabal Ali 2 - Dubai, UAE
                        </p>
                        <p className="Fz(1.4rem) Fw(600) Mt(2.5rem)" >+212 700 122 123</p>
                        <p className="Fz(1.4rem) Fw(600) Mt(2.5rem)" >contact@z-technology.ma</p>
                        <p className="Fz(1.4rem) Fw(600) Mt(2.5rem)" >9:00 - 18:00</p>
                    </div>
                </div>

                <div className="D(n) D(f)--sm Fld(c) Ai(c)">
                    <p className="Fz(1.6rem) Fw(500) C(#fff)">{t("Do You Want To Become a Reseller")}?</p>
                    <p className="Fz(3.6rem) Fw(700) C(#fff)">{t("Let's Talk")}</p>

                    <ButtonBase style={{ borderRadius: '5rem', width: '60%', margin: '1.5rem auto 0 auto' }}>
                        <div className="H(5rem) Px(3rem) W(100%) Bgc(#fff) Bgc(#3e106e):h box Trs(trs-d) D(f) Ai(c) Bdrs(5rem)">
                            <a href="mailto:contact@bridgetv.africa">
                                <p className="Fw(600) Fz(1.6rem) C(#000) box:h_C(#fff) Trs(trs-d) Ta(c)! Mx(a)">{t("Contact Us")}</p>
                            </a>
                        </div>
                    </ButtonBase>

                    <IconButton className="scroll-top-btn" href="#top">
                        <ExpandLess className="icon" />
                    </IconButton>
                </div>
            </div>
            <div className="D(f) Maw(130rem) Mt(-6rem) Mx(a) Jc(sb) Ai(c) P(3rem) Fld(c) Fld(r)--sm">

                <div className="D(f) Ai(c)">
                    <img className="W(4rem)" src={Zlogo} alt="z-technology logo" />
                    <p className="Fz(1.2rem) Fw(600)">{t("Designed by Z-TECHNOLOGY")}</p>
                </div>

                <p className="Fz(1.2rem) Fw(600)">
                    {t("Publisher Terms")}
                    <span className="Fz(5rem) Px(5px) C(#3e106f) Lh(0)">.</span>
                    {t("Terms Of Service")}
                    <span className="Fz(5rem) Px(5px) C(#3e106f) Lh(0)">.</span>
                    {t("Privacy Policy")}
                </p>
                <div className="Mend(-3rem)">
                    <SocialIcons width="4rem" iconSize="2rem" background="default" />
                </div>
            </div>
        </footer>
    )
}

export default Footer;
