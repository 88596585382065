import React, { ReactElement } from 'react'

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    className?: string,
    gradientId?: string
}

function LgIcon(props: Props): ReactElement {
    return (
        <svg viewBox="0 0 269.17 270.42" {...props} fill={`url(#${props.gradientId})`}>
            <defs>
                <linearGradient id={props.gradientId} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#b8bcd8" />
                    <stop offset="1" stop-color="#b8bcd8" />
                </linearGradient>
            </defs>
            <path d="M134.49,0c-181.76,8.75-176.87,264,0,270.42C316.5,261.73,311.6,6.39,134.49,0Zm32.39,183.82v10.59H129.19V75.88H140V183.82Zm47.73,31.41C155.15,274,60.43,250,30.5,179.75-2.56,101.12,57.84,18.57,140,22.05V32.62C43.31,29.69-1.09,140.87,60.09,205.75,125,272.47,232,228.21,237,140.53H167V130.06H243.3l4.45,0A114.35,114.35,0,0,1,214.61,215.23Zm-107-123.18c-1.3,22.13-31.82,20.27-32.44,0C76.49,69.77,107,71.71,107.64,92.05Z" />
        </svg>

    )
}

export default LgIcon;