import clsx from 'clsx';
import React, { ReactElement, useContext, useRef } from 'react'
import { SlideContext } from './Slide.component';

interface Props {
    children: React.ReactNode,
    className?: string
}

interface dataType {
    index: number,
    width: number,
    left: number
}

function SlideWraper({children,className}: Props): ReactElement {

    const {state} = useContext(SlideContext); 

    const handleRef = (trackRef: HTMLDivElement)=>{
        
        

        if (trackRef) {
            
            const data:dataType[] = [];

            let num = 0;

            trackRef.childNodes.forEach((_,i)=>{

                const item = _ as HTMLDivElement;

                data.push({
                    index: i,
                    width: item.clientWidth,
                    left: num,
                });

                num = num + item.clientWidth;

            });

            state.current = {
                element: trackRef,
                data: data
            }

        }
    }

    return (
        <div className={clsx('slide-track',className)} ref={handleRef}>
            {children}
        </div>
    )
}

export default SlideWraper
