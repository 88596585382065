import React, { ReactElement } from 'react'

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    className?: string,
    gradientId?: string

}

function AppleIcon(props: Props): ReactElement {
    return (
        <svg viewBox="0 0 35 40" {...props} fill={`url(#${props.gradientId})`}>
            <defs>
                <linearGradient id={props.gradientId} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#b8bcd8" />
                    <stop offset="1" stop-color="#b8bcd8" />
                </linearGradient>
            </defs>
            <path d="M4.76 15.31C1.67 20.66 3.63 28.79 7.04 33.71C8.75 36.18 10.47 38.39 12.83 38.39C12.87 38.39 12.92 38.39 12.96 38.38C14.07 38.34 14.87 38 15.65 37.67C16.52 37.3 17.42 36.91 18.83 36.91C20.17 36.91 21.03 37.28 21.86 37.64C22.67 38 23.51 38.36 24.76 38.34C27.42 38.29 29.05 35.9 30.49 33.8C31.99 31.6 32.74 29.47 33 28.65L33.01 28.62C33.06 28.48 32.99 28.32 32.85 28.26C32.85 28.25 32.83 28.25 32.83 28.25C32.36 28.05 28.25 26.23 28.21 21.26C28.17 17.23 31.29 15.09 31.92 14.7L31.95 14.69C32.01 14.64 32.06 14.57 32.08 14.49C32.09 14.41 32.08 14.33 32.03 14.27C29.88 11.11 26.58 10.64 25.25 10.58C25.05 10.56 24.85 10.55 24.65 10.55C23.09 10.55 21.6 11.14 20.4 11.61C19.57 11.94 18.85 12.22 18.36 12.22C17.8 12.22 17.08 11.94 16.25 11.61C15.13 11.16 13.87 10.66 12.53 10.66C12.5 10.66 12.46 10.66 12.43 10.66C9.32 10.71 6.38 12.49 4.76 15.31ZM25.25 1.9C23.36 1.97 21.1 3.14 19.75 4.72C18.6 6.05 17.48 8.27 17.78 10.51C17.8 10.65 17.91 10.76 18.05 10.77C18.18 10.78 18.31 10.78 18.44 10.78C20.28 10.78 22.27 9.76 23.63 8.12C25.05 6.38 25.78 4.16 25.56 2.16C25.54 2.01 25.4 1.89 25.25 1.9Z" />
        </svg>
    )
}

export default AppleIcon;

