import React, { ReactElement, useContext, useEffect, useState } from 'react';
import SectionHeader from '../../molecules/section-header/SectionHeader.mole';
import platformsImage from '../../assets/images/download.png';
import RoundButton from '../round-button/RoundButton.component';
import { ButtonBase, Chip, ClickAwayListener, Dialog, Fade, IconButton, Popper, Tooltip } from '@material-ui/core';
import { ChevronLeft, ChevronRight, CloudDownload, ExpandMore, FontDownload, Tv } from '@material-ui/icons';
import LgIcon from '../../assets/icons/Lg.icon';
import AndroidIcon from '../../assets/icons/Phone.icon';
import AppleIcon from '../../assets/icons/Apple.icon';
import WindowsIcon from '../../assets/icons/Windows.icon';
import SamsungIcon from '../../assets/icons/Samsung.icon';
import DownloadIcon from '../../assets/icons/Download.icon';
import './download.style.scss';
import clsx from 'clsx';
import useSize from '../../hooks/size.hook';
import Hide from '../../molecules/Hide.mole';
import Slide from '../../molecules/slide/Slide.component';
import SlideBtns, { BtnsContext } from '../../molecules/slide/SlideBtns.component';
import SlideWraper from '../../molecules/slide/SlideWraper.component';
import SlideItem from '../../molecules/slide/SlideItem.component';
import AppleIconSimple from '@material-ui/icons/Apple'
import AndroidIconSimple from '@material-ui/icons/Android';
// import AndroidIconSimple from '@material-ui/icons/windows';

import androidOs from '../../assets/images/os/android.png';
import iOs from '../../assets/images/os/ios.png';
import pcOs from '../../assets/images/os/pc.png';
import tvOs from '../../assets/images/os/tv.png'
// import mackbook from '../../assets/images/os/mackbook.png';


import ipadOS from '../../assets/images/ipad-os.png';
import { useTranslation } from "react-i18next";

interface Props {

}

const platforms = [
    {
        icon: SamsungIcon,
        label: 'Samsung'
    },
    {
        icon: WindowsIcon,
        label: 'PC'
    },
    {
        icon: AppleIcon,
        label: 'Mac'
    },
    {
        icon: AppleIcon,
        label: 'IOS'
    },
    {
        icon: AndroidIcon,
        label: 'Phone'
    },
    {
        icon: LgIcon,
        label: 'LG'
    },
]

interface BtnsProps {

}

const Btns = ({ }: BtnsProps) => {

    const { index, setIndex } = useContext(BtnsContext);
    const size = useSize();

    const handleClick = (i: number) => {
        setIndex(i)
    }

    useEffect(() => {
        console.log(index)
    })

    const handleNext = () => {
        if (index < platforms.length - 1) {
            setIndex((pre: number) => pre + 1)
        }
    }

    const handlePrev = () => {
        if (index > 0) {
            setIndex((pre: number) => pre - 1)
        }
    }

    return (
        <div className="download-options">
            <IconButton style={{ border: '1px solid #cdd9fa' }} onClick={handlePrev}>
                <ChevronLeft />
            </IconButton>
            <div className="platform-container">
                {platforms.map((item, i) => (
                    <div key={item.label} className={clsx('platform', i !== index && size < 700 && 'D(n)')} onClick={() => handleClick(i)}>
                        <div className="icon-container">
                            <item.icon gradientId={`id--${item.label}`} className={clsx('icon', item.label, index === i && 'active')} />
                        </div>
                        <p className="tool-tip">{item.label}</p>
                    </div>
                ))}
            </div>
            <IconButton style={{ border: '1px solid #cdd9fa' }} onClick={handleNext}>
                <ChevronRight />
            </IconButton>
        </div>
    )
}

interface DownloadOptionProps {
    img: string,
    target: string,
    version: string,
    isAvailable: boolean,
    lowestSupportedVersion: number | string,
    icon: ReactElement,
    imageClassName?: string
}

const DownloadOption = (props: DownloadOptionProps) => {

    const { t } = useTranslation();

    return (
        <SlideItem className="D(f) Jc(c) Fld(c) Ai(c)">
            <p className="Fz(3rem) title">
                {t("Download BRIDGETV FOR")} <span className="Fw(700)">{t(props.target)}</span>
            </p>
            <div className="Mt(3rem) Ta(c)">
                <img className={clsx(props.imageClassName, 'mockup-image')} src={props.img} />
            </div>

            <div className="Bgc(#f8f8f8) Bdrs(1rem) D(f) Ov(h) H(14rem) download-info P(2rem)">
                <div className="Bgc(#f3f3f2) Px(3rem) D(f) Ai(c) Fld(c) Jc(c) Bdrs(1rem) P(1rem)">
                    {props.icon}
                    <p className="Fz(1.8rem) Fw(600) C(#6f6f6f)">{t(props.target)}</p>
                </div>
                <div className="Px(3rem) D(f) Ai(c) Fld(c) Jc(c)">
                    <p className=" Fz(2.4rem) Fw(600)">{props.version}</p>
                    <p className="Fz(1.6rem) C(#6f6f6f)">{t("Release notes")}</p>
                </div>
                <div className="Px(3rem) D(f) Ai(c) Fld(c) Jc(c)">
                    <p className=" Fz(2.4rem) Fw(600)">{t(props.target)}</p>
                    <p className="Fz(1.6rem) C(#6f6f6f)">{t(`For version ${props.lowestSupportedVersion} or later`)}</p>
                </div>
                <div className="D(f) Ai(c) Mx(a)">
                    <RoundButton disabled={!props.isAvailable} style={{ borderRadius: '1rem' }}
                        background={props.isAvailable ? 'gradient' : 'default'}
                        shadow={props.isAvailable ? 'gradient' : 'none'}
                        height="7rem"
                    >
                        <Hide open={props.isAvailable}>
                            <DownloadIcon width="3rem" className="Mend(2rem)" fill="#fff" />
                        </Hide>
                        <p className="label">{props.isAvailable ? t('Download') : t('Coming Soon') + '...'}</p>
                    </RoundButton>
                </div>
            </div>

            <p className="Fz(1.4rem) C(##a29aa9) Mt(1rem)">{t("By downloading, you agree to the Terms and Conditions")}.</p>
        </SlideItem>
    )
}

function Download({ }: Props): ReactElement {

    const [active, setActive] = useState(0);
    const size = useSize();
    const ActiveElement = platforms[active];
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleOption = (num: number) => {
        setActive(num);
        handleClose();
    }

    return (
        <section className="download-section" id="download">
            <div className="header-container">
                <div className="title-bar left">
                    <div className="bar"></div>
                    <p className="title">{t("DON'T LISTEN TO WHAT")}</p>
                </div>
                <SectionHeader
                    title="Download"
                    subTitle="ALL YOUR DEVICES"
                    description={t("Apple Store, Google Play")}
                    variant="light"
                />
                <div className="divider"></div>
                <div className="title-bar right">
                    <div className="bar"></div>
                    <p className="title"></p>
                </div>
            </div>
            <div className="container">
                <img className="platform-image" src={platformsImage} alt="" />



                <div className="Mt(2rem)">
                    <p className="C(#20083a) Fz(4.2rem) Ta(c)!">
                        <span className="Fw(700)">{t("BRIDGETV")} </span>
                        {t("is available for all devices")}.
                    </p>
                </div>
                <div >
                    <Slide width="100vw">
                        <SlideBtns className="D(f) Jc(c)">
                            <Btns />
                        </SlideBtns>
                        <SlideWraper>
                            <DownloadOption
                                icon={<Tv className="Fz(5rem)!" fill="#000" />}
                                img={tvOs}
                                imageClassName="H(55rem)"
                                isAvailable={true}
                                lowestSupportedVersion="4"
                                target="TIZEN OS"
                                version="1.00.1"
                            />

                            <DownloadOption
                                icon={<WindowsIcon gradientId="9398300" className="W(3.5rem)! Fill(#000)" />}
                                img={pcOs}
                                isAvailable={false}
                                lowestSupportedVersion="7"
                                target="Windows"
                                version="1.00.1"
                            />

                            <DownloadOption
                                icon={<AppleIconSimple className="Fz(5rem)!" fill="#000" />}
                                img={pcOs}
                                isAvailable={false}
                                lowestSupportedVersion="High Sierra"
                                target="Mac OS"
                                version="1.00.1"
                            />
                            <DownloadOption
                                icon={<AppleIconSimple className="Fz(5rem)!" fill="#000" />}
                                img={iOs}
                                isAvailable={false}
                                lowestSupportedVersion={6}
                                target="iOS"
                                version="1.00.1"
                            />
                            <DownloadOption
                                icon={<AndroidIconSimple className="Fz(5rem)!" fill="#000" />}
                                img={androidOs}
                                isAvailable={false}
                                lowestSupportedVersion={5}
                                target="Android OS"
                                version="1.00.1"
                            />


                            <DownloadOption
                                icon={<Tv className="Fz(5rem)!" fill="#000" />}
                                img={tvOs}
                                isAvailable={true}
                                lowestSupportedVersion="4"
                                target="Web OS"
                                version="1.00.1"
                                imageClassName="H(55rem)"
                            />
                        </SlideWraper>
                    </Slide>
                </div>

                {/* <div className="button-container">
                    <p className="title"><span>Download</span> BridgeTV</p>
                    <p className="sub-title">For Your <span className="gradient-text">{ActiveElement.label}</span></p>
                    <p className="description">
                        Enjoy all your favorite content in several languages, without limits, From any device
                        and in Bluray Quality, it is less than 1 day per day.
                    </p>

                    <RoundButton
                        background="gradient"
                        shadow="gradient"
                        height="6rem"
                        paddingx="6rem"
                    >
                        
                        <p className="label">Download</p>
                    </RoundButton>
                </div> */}

            </div>


        </section>
    )
}

export default Download
