import anime from 'animejs';
import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';

interface Props {
    children: string,
    delay: number
}

function TextAnimation({ children,delay }: Props): ReactElement {

    const containerRef = useRef<HTMLParagraphElement | null>(null);

    useEffect(() => {
        const timeline = anime.timeline({ loop: true,endDelay: delay });

        timeline.add({
            targets: containerRef.current?.childNodes,
            opacity: [0, 1],
            easing: 'easeInOutQuad',
            duration: 2250,
            delay: (el, i) => 180 * (i + 1)
        })

    }, [])

    return (
        <span ref={containerRef} >
            {children.split('').map((letter,i)=>(
                <span key={`letter-animation--${i}--${letter}`} >{letter}</span>
            ))}
        </span>
    )
}

export default TextAnimation
