import React, { ReactElement } from 'react';
import { useTranslation } from "react-i18next";
import './section-header.style.scss';

interface Props {
    title: string,
    subTitle: string,
    description: React.ReactNode | string,
    variant?: 'dark' | 'light'
}

function SectionHeader({ variant = "dark", ...props }: Props): ReactElement {

    const { t } = useTranslation();

    return (
        <div className={`section-header ${variant}`}>
            <div className="bar-container">
                <div className="bar"></div>
                <p className="sub-title">{t(props.subTitle)}</p>
            </div>
            <p className="main-title">{t(props.title)}</p>
            <p className="description">
                {props.description}
            </p>
        </div>
    )
}

export default SectionHeader
