import { Fade, IconButton } from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import { set } from 'animejs';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Hide from '../../molecules/Hide.mole';
import './scroll-progress.style.scss';

interface Props {
    headerHeight?: number
}

function ScrollProgress({headerHeight}: Props): ReactElement {

    const indicatorRef = useRef<HTMLDivElement>(null);
    const [hideBtn, setHideBtn] = useState(true);

    useEffect(() => {
        document.addEventListener('scroll', (event) => {


            const totalHeight = document.documentElement.offsetHeight;
            const viewportHeight = window.innerHeight
            const scrolled = Math.max(document.body.scrollTop, document.documentElement.scrollTop);

            if (indicatorRef.current) {
                const parcent = scrolled / (totalHeight - viewportHeight) * 100;
                indicatorRef.current.style.width = `${parcent}%`;

                if (parcent > 60 && hideBtn) {
                    setHideBtn(false)
                }else{
                    setHideBtn(true)
                }
            }

        });
    }, []);

    return (
        <div className="scroll-progress-indicator" ref={indicatorRef} style={{top: headerHeight}}>
            {/* Scroll To the Top Btn
            <Hide open={!hideBtn}>
                <Fade in={!hideBtn} timeout={300}>
                    
                </Fade>
            </Hide> */}
        </div>
    )
}

export default ScrollProgress
