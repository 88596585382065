import { atom } from "recoil";

export const floatingButtonState = atom({
    default: true,
    key: 'FLOATNG_BUTTON_STATE'
});

export const popupFormState = atom({
    default: false,
    key: 'POPUP_FORM'
})

export const successPopupState = atom({
    default: false,
    key: 'SUCCESS_POPUP'
})