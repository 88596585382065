import React, { ReactElement } from 'react'

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    className?: string,
    gradientId?: string
}

function WindowsIcon(props: Props): ReactElement {
    return (
        <svg viewBox="0 0 512 512" {...props} fill={`url(#${props.gradientId})`}>
            <defs>
                <linearGradient id={props.gradientId} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#b8bcd8" />
                    <stop offset="1" stop-color="#b8bcd8" />
                </linearGradient>
            </defs>
            <polygon points="0,80 0,240 224,240 224,52 		" />
            <polygon points="256,48 256,240 512,240 512,16 		" />
            <polygon points="256,272 256,464 512,496 512,272 		" />
            <polygon points="0,272 0,432 224,460 224,272 		" />
        </svg>

    )
}

export default WindowsIcon;