import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#20083a'
        },
        secondary: {
            main: '#8e48ee'
        }
    }
});
