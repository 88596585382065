import React, { ReactElement } from 'react';
import illustrationGif from '../../assets/animations/bridge.gif';
import logos from '../../assets/images/logos.png';
import './gif.style.scss';

interface Props {
    
}

function GifSection({}: Props): ReactElement {
    return (
        <section className="illustration-gif-section">
            <img src={logos} alt="background-logos" className="logos" />
            <img src={illustrationGif} className="gif-image" alt="Illustartion Gif Image" />
        </section>
    )
}

export default GifSection
