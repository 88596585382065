import clsx from 'clsx'
import React, { ReactElement, useContext } from 'react'
import { SlideContext } from './Slide.component';

interface Props {
    style?: React.CSSProperties,
    className?: string,
    children?: React.ReactNode,
    width?: string,
}

function SlideItem(props: Props): ReactElement {

      const { state, width } = useContext(SlideContext);

      console.log(props.width,width,'Width ')

    return (
        <div className={clsx("slide-item",props.className)} style={{...props.style,minWidth: props.width ? props.width : width,maxWidth: props.width ? props.width : width}}> 
            {props.children}
        </div>
    )
}

export default SlideItem
