import { ButtonBase } from '@material-ui/core'
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import './toggle-btn.style.scss';

interface Props {
    onChange?: (a:boolean)=>void,
    className?: string,
    defaultState: boolean
}

function ToggleButton({onChange,className,defaultState}: Props): ReactElement {

    const [active,setActive] = useState(defaultState);

    const handleClick = ()=>{
        onChange && onChange(active);
        setActive(pre=>!pre)
    }

    return (
        <ButtonBase className={className} style={{borderRadius: '4rem'}} onClick={handleClick}>
            <div className={clsx('toggle-btn',active && 'active')}>
                <div className="toggle-circle"></div>
            </div>
        </ButtonBase>
    )
}

export default ToggleButton;
