import { Backdrop, Box, ButtonBase, ClickAwayListener, Dialog, Fade, IconButton, InputAdornment, makeStyles, Modal, Popper, TextField } from '@material-ui/core';
import { Close, Done, ExpandLess, ExpandMore, Message, PeopleSharp, Star } from '@material-ui/icons';
import React, { FormEvent, ReactElement, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import AppLogo from '../../molecules/app-logo/AppLogo.mole';
import RoundButton from '../round-button/RoundButton.component';
import { floatingButtonState, popupFormState, successPopupState } from './popup.atom';
import EmailIcon from '@material-ui/icons/Email';

import './popup.style.scss';
import WhatsAppIcon from '../../assets/icons/WhatsApp.icon';
import Hide from '../../molecules/Hide.mole';
import useSize from '../../hooks/size.hook';
import logo from '../../assets/images/logo.png';
import SuccessIcon from '../../molecules/success-animation/Success.icon';
import validateEmail from '../../utils/email.validator';
import { countryImage } from '../../importer';
import PersonIcon from '@material-ui/icons/Person';
import { useIsInit } from '../../hooks/isInit.hook';
import CheckBox from '../../molecules/CheckBox.mole';
import FreeLink from '../../molecules/FreeLink.mole';
import { useTranslation } from "react-i18next";


interface Props {

}

const useStyles = makeStyles({
    root: {
        backgroundColor: '#180540ba !important',
    }
});


const FloatingBtnBig = () => {

    const [open, setOpen] = useRecoilState(floatingButtonState);
    const setOpenForm = useSetRecoilState(popupFormState);
    const { t } = useTranslation();


    const handleClickAway = () => {
        setOpen(false)
    }

    const handleClick = () => {
        setOpen(false);
        setOpenForm(true);
    }

    return (
        <Hide open={open}>
            <Fade in={open} timeout={500}>
                <div className="try-for-free-select-popup">
                    <Box width="max-content" margin="auto">
                        <AppLogo />
                    </Box>
                    <IconButton className="close-btn" onClick={handleClickAway}>
                        <Close fontSize="large" />
                    </IconButton>
                    <div className="text-container">
                        <p className="title">{t("Try BridgeTV For Free")}</p>
                        <p className="time">{t("Free 48h Trial code")}</p>
                        <p className="label">{t("Receive Your Trial Via Email")}</p>
                    </div>
                    <div className="btn-container">

                        <RoundButton
                            height="6rem"
                            background="gradient"
                            shadow="gradient"
                            width="100%"
                        // onClick={handleClick}
                        >
                            <FreeLink>
                                <p className="label">{t("Try BridgeTV For Free")}</p>
                            </FreeLink>
                        </RoundButton>
                    </div>
                </div>
            </Fade>
        </Hide>
    )
}

const FloatingBtnSmall = () => {

    const [open, setOpen] = useRecoilState(floatingButtonState);
    const setPopupOpen = useSetRecoilState(popupFormState);


    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Hide open={open}>
            <div className="floating-btn-small">
                <div className="wraper">
                    <div className="icon-container">
                        <img className="logo" src={logo} alt="logo" />
                    </div>
                    <div className="text-container">
                        <p className="main-label">BridgeTV For Free</p>
                        <div className="rating">
                            <p className="text">Enjoy 48h of entertainment for free</p>
                        </div>
                    </div>
                </div>
                <div className="btn-container">
                    <RoundButton height="4rem" background="gradient" shadow="gradient" >
                        <FreeLink>
                            <p className="btn-label">TRY FOR FREE</p>
                        </FreeLink>
                    </RoundButton>
                </div>
                <div className="close-btn">
                    <IconButton onClick={handleClose}>
                        <Close className="icon" fontSize="large" />
                    </IconButton>
                </div>
            </div>
        </Hide>
    )
}

const UseFloatingBtnStyle = makeStyles({
    root: {
        width: '6rem',
        height: '6rem',
        borderRadius: '50%',
        background: 'rgba(255,255,255,.9)',
        position: 'absolute',

        display: 'flex'
    },
    icon: {
        width: '3rem',
        margin: 'auto'
    }
})

const FloatingButton = () => {

    const size = useSize();
    const [open, setOpen] = useRecoilState(floatingButtonState);
    const classes = UseFloatingBtnStyle();


    return (
        <Hide open={open} fallback={
            <Hide open={size > 600}>
                <div className="floating-btn">
                    <div className="circle-floating" ></div>
                    <div className="circle-floating" style={{ animationDelay: '1s' }}></div>
                    <div className="circle-floating" style={{ animationDelay: '1.5s' }}></div>
                    <ButtonBase className={classes.root} onClick={() => setOpen(true)}>
                        <img src={logo} className={classes.icon} alt="logo" />
                    </ButtonBase>
                </div>
            </Hide>
        }>
            {size < 900 ? <FloatingBtnSmall /> : <FloatingBtnBig />}
        </Hide>
    )
}

const SuccessPopup = () => {

    const [open, SetOpen] = useRecoilState(successPopupState);
    const classes = useStyles();

    const handleClose = () => {
        SetOpen(false);
    }

    return (
        <Modal open={open} className="popup-success-modal" BackdropProps={{ className: classes.root }}>
            {/* <Hide open={open}> */}
            <div className="popup-success">
                <IconButton className="close-btn" onClick={handleClose}>
                    <Close fontSize="large" className="icon" />
                </IconButton>
                <div className="icon-wraper">
                    <SuccessIcon width="29rem" />
                </div>
                <div className="success-text">
                    <p className="title">Success!</p>
                    <p className="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
                    </p>
                </div>
                <RoundButton
                    height="6rem"
                    background="gradient"
                    shadow="gradient"
                    width="100%"
                    onClick={handleClose}
                >
                    <p className="label">Thank You!</p>
                </RoundButton>
            </div>
            {/* </Hide> */}
        </Modal>
    )
}

interface CustomInput {
    label?: string,
    placeholder: string,
    type: 'text' | 'email',
    validator: (arg: string) => boolean,
    errorText?: string,
    inputIcon?: ReactElement
}

const CustomInput = (props: CustomInput) => {

    const [invalid, setInvalid] = useState(false);
    const [value, setValue] = useState('');
    const isInit = useIsInit();

    const handleInvalid = (event: FormEvent<HTMLInputElement>) => {
        event.preventDefault();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const inputValue = event.currentTarget.value;

        setValue(inputValue);

        if (props.validator(inputValue)) {
            setInvalid(false);
        } else {
            setInvalid(true)
        }
    }

    return (
        <div className="container">
            <label className="input-label" htmlFor="first-name">{props.label}</label>
            <div className="input-wraper" tabIndex={-1}>
                <input value={value} onChange={handleChange} onInvalid={handleInvalid} type={props.type} className="input" id="first-name" required={true} placeholder={props.placeholder} />

                <div className="input-icon">
                    {props.inputIcon}
                </div>
            </div>
        </div>
    )
}

const data = [
    {
        key: 'england--image',
        image: countryImage[0],
        label: 'ENG'
    },
    {
        key: 'russia--image',
        image: countryImage[1],
        label: 'RUS'
    },
    {
        key: 'zimbabwe--image',
        image: countryImage[2],
        label: 'ZIM'
    }
]

const PopupForm = () => {

    const [open, SetOpen] = useRecoilState(popupFormState);
    const setSuccessPopup = useSetRecoilState(successPopupState);
    const classes = useStyles();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSuccessPopup(true);
        SetOpen(false);
    }

    const handleClose = () => {
        SetOpen(false);
    }



    return (
        <Modal onClose={handleClose} open={open} className="popup-form-modal" BackdropProps={{ className: classes.root, timeout: 500 }}>
            <Fade in={open} timeout={500}>
                <div className="popup-form">
                    <IconButton className="close-btn" onClick={handleClose}>
                        <Close fontSize="large" className="icon" color="inherit" />
                    </IconButton>
                    <div className="popup-left">
                        <Box width="max-content" margin="auto" mb="3rem">
                            <AppLogo />
                        </Box>
                        <div className="text-container">
                            <p className="main-title">Try BridgeTV For Free 48h</p>
                            <p className="description">
                                Please tell a bit about you so that we can personalize your onboarding experience
                            </p>
                        </div>
                        <div className="divider-line"></div>

                        <form className="inputs-container" onSubmitCapture={handleSubmit}>
                            <CustomInput
                                // label="First-Name(*):"
                                placeholder="name..."
                                validator={(value: string) => value !== ''}
                                type="text"
                                errorText="Please Provide a First Name"
                                inputIcon={<PersonIcon />}
                            />

                            <div className="Mt(-2rem)">
                                <CustomInput
                                    // label=""
                                    placeholder="email..."
                                    validator={validateEmail}
                                    type="email"
                                    errorText="Please Provide a valid Email Address"
                                    inputIcon={<EmailIcon />}
                                />
                            </div>
                            <div className="accept-newsletter">
                                <CheckBox />
                                <p className="label">Subscribe to BridgeTV News</p>
                            </div>
                            <ButtonBase className="submit-btn" type="submit">
                                <WhatsAppIcon className="btn-icon" />
                                <p className="label">Try Bridge TV Now</p>
                            </ButtonBase>
                        </form>


                    </div>
                </div>
            </Fade>
        </Modal>
    )
}


function Popup({ }: Props): ReactElement {
    return (
        <React.Fragment>
            <FloatingButton />
            <PopupForm />
            <SuccessPopup />
        </React.Fragment>
    )
}

export default Popup
