import React, { ReactElement, useEffect, useRef, useState } from 'react'
import SectionHeader from '../../molecules/section-header/SectionHeader.mole';
import './features.style.scss';
import laptopMobile from '../../assets/images/laptop-mobile.png';
import RoundButton from '../round-button/RoundButton.component';
import useSize from '../../hooks/size.hook';
import Hide from '../../molecules/Hide.mole';
import { ButtonBase, IconButton } from '@material-ui/core';
import { WatchLater, ChevronLeft, ChevronRight, ContactSupport, HighQuality, Public, RecentActors, SupervisorAccount } from '@material-ui/icons/';
import clsx from 'clsx';
import { popupFormState } from '../popups/popup.atom';
import { useSetRecoilState } from 'recoil';
import feature1 from '../../assets/images/feature1.png';
import feature2 from '../../assets/images/feature2.png';
import feature3 from '../../assets/images/feature3.png';
import feature4 from '../../assets/images/feature4.png';
import feature5 from '../../assets/images/feature5.png';
import feature6 from '../../assets/images/feature6.png';
import FreeLink from '../../molecules/FreeLink.mole';
import { useTranslation } from "react-i18next";


interface Props {

}
interface SlideProps {
    currentIndex: number,
    updateRef: React.MutableRefObject<(arg: boolean) => void | null>
}

const SlideIndicator = ({ updateRef }: SlideProps) => {

    const [currentIndex, setCurrentIndex] = useState(1);
    const indicatorLength = 6;

    const updater = (next: boolean) => {
        if (next) {
            setCurrentIndex(pre => {
                if (pre < indicatorLength) {
                    return pre + 1;
                }

                return pre
            });
        } else {
            setCurrentIndex(pre => {
                if (pre > 1) {
                    return pre - 1;
                }

                return pre
            });
        }
    }

    useEffect(() => {
        updateRef.current = updater;
    }, []);

    return (
        <div className="slide-indicator">
            {[1, 2, 3, 4, 5, 6].map(item => (
                <div className={clsx('item', item === currentIndex && 'active')}>
                    <div className="indicator-circle"></div>
                </div>
            ))}
        </div>
    )
}

interface FeatureItemProp {
    title: string,
    description: string,
    image: string
}

const FeatureItem = ({ title, description, image }: FeatureItemProp) => {


    return (
        <div className="card-wraper">
            <div className="info-card Mx(a)">
                <img className="Maw(100%) H(300px)--sm H(100px)" src={image} alt="" />
                <div className="icon-title-wraper">
                    {/* <div className="image-icon Mend(1rem)"> */}
                    {/* </div> */}
                    <p className="title Miw(maxc)!">{title}</p>
                </div>
                <p className="description Mt(-10px)">
                    {description}
                </p>
            </div>
        </div>
    )
}

function Features({ }: Props): ReactElement {

    const size = useSize();
    const slideRef = useRef<HTMLDivElement | null>(null);
    const slideIndicatorUpdateref = useRef<any>(null);
    const setOpenForm = useSetRecoilState(popupFormState);
    const { t } = useTranslation();


    const handleNext = () => {

        if (slideRef.current) {
            const rect = slideRef.current.getBoundingClientRect();

            slideRef.current?.scrollBy({
                left: rect.width,
                behavior: 'smooth'
            });

        }

        if (slideIndicatorUpdateref.current) {
            slideIndicatorUpdateref.current(true);
        }
    }

    const handlePrev = () => {
        if (slideRef.current) {
            const rect = slideRef.current.getBoundingClientRect();

            slideRef.current?.scrollBy({
                left: rect.width * -1,
                behavior: 'smooth'
            });
        }

        if (slideIndicatorUpdateref.current) {
            slideIndicatorUpdateref.current(false);
        }
    }

    return (
        <section className="features" id="features">
            <div className="header-container">
                <SectionHeader
                    title={t("Features")}
                    subTitle={t("Why US") + '?'}
                    description={<>{t("Why Should You Choose")} <span className="gradient-text">{t("BRIDGETV")}</span></>}
                    variant={size < 1000 ? 'light' : 'dark'}
                />
            </div>

            <div className="container Fld(c)">





                {/* Image  */}
                <div className="image-side Pb(5rem) Mt(4rem)">
                    <img src={laptopMobile} alt="" />
                    <div className="text-container">
                        <p className="title"><span className="gradient-text">{t("Don't Miss")}</span> {t("Your Chance")}!</p>
                        <p className="title-small">{t("We Are Always Glad To See You")}!</p>
                    </div>

                    <div className="D(f) Fld(c) Fld(r)--xs">
                        <RoundButton
                            height="6rem"
                            width="25rem"
                            background="gradient"
                            shadow="gradient"
                        // onClick={() => setOpenForm(true)}
                        >
                            <FreeLink>
                                <p className="label">{t("Try Bridge TV For Free")}</p>
                            </FreeLink>
                        </RoundButton>
                        <div className="W(2rem) H(2rem)"></div>
                        <ButtonBase className="btn-subscribe W(25rem) H(5rem) H(6rem)--xs">
                            <p className="btn-text">{t("Subscribe")}</p>
                        </ButtonBase>
                    </div>
                </div>



                {/* Features Section Start *************************************** */}

                <div className="info-side">
                    <div className="slide">
                        <Hide open={size < 1000}>
                            <IconButton onClick={handlePrev} style={{ width: '5rem', backgroundColor: '#fff' }}>
                                <ChevronLeft fontSize="large" />
                            </IconButton>
                        </Hide>
                        <div className="slide-wraper" ref={slideRef}>

                            {[feature1, feature2, feature3, feature4, feature5, feature6].map((image, i) => (
                                <FeatureItem
                                    title={t(`section.features.${i}.title`)}
                                    description={t(`section.features.${i}.description`)}
                                    image={image}
                                />
                            ))}
                        </div>
                        <Hide open={size < 1000}>
                            <IconButton onClick={handleNext} style={{ width: '5rem', backgroundColor: '#fff' }}>
                                <ChevronRight fontSize="large" />
                            </IconButton>
                        </Hide>
                    </div>
                    <Hide open={size < 1000}>
                        <SlideIndicator updateRef={slideIndicatorUpdateref} currentIndex={1} />
                    </Hide>
                </div>



                {/* Features Section End *************************************** */}

            </div>
        </section>
    )
}

export default Features
