import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import SectionHeader from '../../molecules/section-header/SectionHeader.mole';
import RoundButton from '../round-button/RoundButton.component';

import proImage from '../../assets/images/pro.png';
import basicImage from '../../assets/images/basic.png';
import unlimitedImage from '../../assets/images/unlimited.png';
import { ButtonBase, ClickAwayListener, Fade, Popper, Switch } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './pricing.style.scss';

import basicPlantil from '../../assets/images/BasicPlanILL.png'
import basicPlantilShadow from '../../assets/images/BasicPlanILLShadow.png'
import unlimitedTop from '../../assets/images/unlimitedTop.png'
import unlimitedMid from '../../assets/images/unlimitedMid.png'
import unlimitedBottom from '../../assets/images/unlimitedBottom.png'
import unlimitedShadow from '../../assets/images/unlimitedShadow.png'
import CustomerIcon from '../../assets/icons/Customer.icon';
import clsx from 'clsx';
import DevicesIcon from '../../assets/icons/Devices.icon';
import Hide from '../../molecules/Hide.mole';
import ToggleButton from '../../molecules/toggle-button/ToggleButton.component';
import ArrowIndicatorIcon from '../../assets/icons/ArrowIndicator.icon';
import useSize from '../../hooks/size.hook';
import { useTranslation } from "react-i18next";




interface PriceCard {
    package: string,
    amount: number,
    lifetime: string,
    img: string,
    children: ReactNode,
    variant?: 'main',
    devices: number,
    label?: string,
    saving?: number
}

const PriceCard = (props: PriceCard) => {

    const size = useSize();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [screens, setScreens] = useState(1);
    const { t } = useTranslation();

    const handleScreenOption = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handlePopperClose = () => {
        setAnchorEl(null);
    }

    const handleOption = (num: number) => {
        setScreens(num);
        setAnchorEl(null);
    }

    return (
        <div className={clsx('card', props.variant)} tabIndex={0}>
            <div className="top-label">{props.label}</div>
            <div className="image-container">

                <div className="">
                    {/* Images */}
                    <div className="image-wraper">
                        {props.children}
                    </div>

                    {/* Title */}
                    <div className="pack-container">
                        <p className="package gradient-text">
                            {t(props.package)}
                        </p>
                    </div>

                    {/* Price */}
                    <div className="price-wraper small">
                        <p className="price">

                            <span className="amount">{props.amount}
                                <span className="currency">DH</span>
                            </span>
                            <span className="life-time">{props.lifetime}</span>
                        </p>
                    </div>
                </div>

                <div className="D(f) Fld(c) Jc(sb)">
                    {/* Devices */}
                    <div className="">
                        <DevicesIcon width="12rem" />
                        <p className="Fz(1.8rem) Fw(600) Mt(-2rem)">{t(`${props.devices} DEVICES`)}</p>
                        <p className="Fz(1.2rem)">{t("UNLIMITED WATCHING")}</p>
                    </div>

                    <div className="divider"></div>

                    {/* Price */}
                    <div className="price-wraper big">
                        <p className="price">

                            <span className="amount">{props.amount}
                                <span className="currency">DH</span>
                            </span>
                            <span className="life-time">{props.lifetime}</span>
                        </p>
                    </div>

                    <div className="buttons">


                        <RoundButton
                            height="6rem"
                            background="gradient"
                            shadow="gradient"
                            onClick={() => { }}
                            width={size < 450 ? '15rem' : '25rem'}
                        >
                            <p className="label">{t("Subscribe")}</p>
                        </RoundButton>
                    </div>
                </div>
            </div>


        </div>
    )
}

enum PackageType { Monthly, Yearly };

function Pricing(): ReactElement {

    const { t } = useTranslation();
    const [activeDuration, setActiveDuration] = useState(PackageType.Yearly);
    const MonthlyPackage = {
        'Bahamat': 50,
        'Hydra': 100,
        'Cerberos': 60
    }
    const YearlyPackage = {
        'Bahamat': 400,
        'Hydra': 800,
        'Cerberos': 600
    }

    const currentPackage = activeDuration === PackageType.Monthly ? MonthlyPackage : YearlyPackage

    const optionSwitch = (option: PackageType) => {
        return activeDuration === option ? 'default' : 'none';
    }

    const handleToggle = () => {
        if (activeDuration === PackageType.Monthly) {
            setActiveDuration(PackageType.Yearly);
        } else {
            setActiveDuration(PackageType.Monthly);
        }
    }

    return (
        <section className="pricing" id="price">
            <div className="header-container">
                <SectionHeader
                    title={t("PRICING")}
                    subTitle={t("YOU PAY FOR QUALITY") + '!'}
                    description={PackageType.Yearly === activeDuration ? <span className="C(#000)!">
                        <span className="C(#01b289)!">{t("7 days refund")}</span>
                        , {t("no question asked")}.
                    </span> : ''}
                />
            </div>
            <div className="time-btn gap-1">
                <p className="label Fz(1.6rem) Fw(600)" style={{ color: activeDuration === PackageType.Monthly ? '#000' : '#8385a6' }}>{t("Monthly")}</p>
                <ToggleButton defaultState={true} className="Mstart(1rem)!" onChange={handleToggle} />
                <div className="Pos(a)">
                    <p className="Pos(a) C(#01b289) T(-4.5rem) W(maxc) End(-10.5rem) Fz(1rem) Fw(600)">{t("4 MONTHS FREE")}!</p>
                    <ArrowIndicatorIcon className="Pos(a) End(-12rem) T(-4rem)" height="4rem" />
                </div>
                <p className="label Fz(1.6rem) Fw(600)" style={{ color: activeDuration === PackageType.Yearly ? '#000' : '#8385a6' }}>{t("Yearly")}</p>
            </div>
            <div className="card-container gap-4">

                <PriceCard
                    package="Bahamut"
                    amount={currentPackage.Bahamat}
                    lifetime={`/${activeDuration === PackageType.Monthly ? t('month')  : t('year')}`}
                    devices={1}
                    img={basicImage}
                >
                    <div className="choiceImage">
                        <img src={basicPlantil} className="basicTop" />
                        <img src={basicPlantilShadow} className="basicBottom" />
                    </div>
                </PriceCard>

                <PriceCard
                    package="Hydra"
                    amount={currentPackage.Hydra}
                    lifetime={`/${activeDuration === PackageType.Monthly ? t('month') : t('year')}`}
                    img={proImage}
                    variant="main"
                    devices={4}
                    label={t("Most Popular Choice")}
                    saving={100}
                >
                    <div className="choiceImage">
                        <img src={unlimitedTop} className="unlimTop" />
                        <img src={unlimitedMid} className="unlimMid" />
                        <img src={unlimitedBottom} className="unlimBottom" />
                        <img src={unlimitedShadow} className="unlimShadow" />
                    </div>
                </PriceCard>

                <PriceCard
                    package="Cerberos"
                    amount={currentPackage.Cerberos}
                    lifetime={`/${activeDuration === PackageType.Monthly ? t('month')  : t('year')}`}
                    img={unlimitedImage}
                    devices={2}
                >
                    <div className="choiceImage" >
                        <img src={unlimitedMid} className="unlimMid" />
                        <img src={unlimitedBottom} className="unlimBottom" />
                        <img src={unlimitedShadow} className="unlimShadow" />
                    </div>
                </PriceCard>

            </div>
        </section >
    )
}

export default Pricing
