import React, { ReactElement, useState } from 'react'
import FbIcon from '../../assets/icons/Fb.icon';
import MessengerIcon from '../../assets/icons/Messenger.icon';
import TwitterIcon from '../../assets/icons/Twitter.icon';
import WhatsAppIcon from '../../assets/icons/WhatsApp.icon';
import TelegramIcon from '../../assets/icons/Telegram.icon';
import RoundButton from '../round-button/RoundButton.component';
import { Box } from '@material-ui/core';
import './social-icons.style.scss';

interface Props {
    width?: string,
    background?: 'gradient' | 'default',
    direction?: 'row' | 'column',
    iconSize?: string 
}

const SocialIcons = ({width="6rem",background="gradient",direction="row",iconSize="3rem"}:Props) => {

    const [active, setActive] = useState(0);

    return (
        <div className="social-icons" style={{flexDirection: direction}}>
            {[MessengerIcon, WhatsAppIcon, TelegramIcon, TwitterIcon, FbIcon].map((Item, i) => (
                <Box key={`social-icon--${i}`} paddingX="1rem" paddingTop="1rem">
                    <RoundButton
                        background={active === i ? background : 'none'}
                        onClick={() => setActive(i)}
                        shadow="none"
                        style={{
                            padding: 0,
                            height: width,
                            width: width,

                        }}

                    >
                        <Item className="social-icon" fill={active === i ? "#fff" : '#b7bbd7'} height={iconSize} />
                    </RoundButton>
                </Box>
            ))}
        </div>
    )
}

export default SocialIcons
