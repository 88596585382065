import { Accordion, AccordionDetails, AccordionSummary, Box, ClickAwayListener } from '@material-ui/core';
import { Add, AddCircle, Book, CloudDownload, ExpandMore, FiberNew, FiberPin, Group, ImportantDevices, LiveTv, MonetizationOn, PanTool, Payment, Policy, Remove, ShoppingCart, Speed, Tv } from '@material-ui/icons';
import clsx from 'clsx';
import React, { ReactElement, useRef, useState } from 'react'
import useSize from '../../hooks/size.hook';
import CheckBox from '../../molecules/CheckBox.mole';
import Hide from '../../molecules/Hide.mole';
import SectionHeader from '../../molecules/section-header/SectionHeader.mole';
import RoundButton from '../round-button/RoundButton.component';
import './contact.style.scss';
import SquareBox from '../../assets/icons/SuareBox.icon';
import cashplusImage from '../../assets/images/cashplus-logo.png';
import bankLogo from '../../assets/images/bank-logo.png';
import visaLogo from '../../assets/images/visa-logo.png';
import mastercardLogo from '../../assets/images/mastercard-logo.png';
import cmiLogo from '../../assets/images/cmi-logo.png';
import paypalLogo from '../../assets/images/paypal-logo.png';
import sepaLogo from '../../assets/images/sepa-logo.png';
import ConnectIcon from '../../assets/icons/Connect.icon';
import AddReactionIcon from '../../assets/icons/AddReaction.icon';
import appIcon from '../../assets/images/logo.png';
import { useTranslation } from "react-i18next";



interface Props {

}

interface CustomAccordion {
    openDefault?: boolean,
    title: string,
    description?: string,
    children?: ReactElement,
    icon?: ReactElement
}

const CustomAccordion = ({ openDefault = false, title, description, children, icon }: CustomAccordion) => {

    const [open, setOpen] = useState(openDefault);

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Accordion expanded={open} className={clsx('accordion', open && 'active')}>
                <AccordionSummary
                    expandIcon={open ? <Remove style={{ fontSize: '2rem', fill: 'var(--text-light)' }} /> : <Add style={{ fontSize: '2rem' }} />}
                    onClick={() => setOpen(pre => !pre)}
                >
                    <div className="D(f) Ai(c)">
                        {icon}
                        <p className={`accordion-question ${open && 'gradient-text'}`} >{title}</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                    <Hide open={Boolean(description)}>
                        <p className="accordion-answer">
                            {description}
                        </p>
                    </Hide>
                    {children}
                </AccordionDetails>
            </Accordion>
        </ClickAwayListener>
    )
}

const tabData = [
    {
        label: 'General',
        icon: <Tv className="Fz(3rem)! Mend(1rem) C(#7115f4) Trs(trs-d) icon" />,
        key: 'general-tab'
    },
    {
        label: 'Payment',
        icon: <MonetizationOn className="Fz(3rem)! Mend(1rem) C(#7115f4) Trs(trs-d) icon" />,
        key: 'payment-tab'
    },
    {
        label: 'Application',
        icon: <img className="W(2.5rem) Mend(1rem)" src={appIcon} />,
        key: 'application-tab'
    },
]

function Contact({ }: Props): ReactElement {

    const [activeTab, setActiveTab] = useState('General');
    const size = useSize();
    const slideRef = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();

    const handleSlide = (index: number, item: string) => {
        if (slideRef.current) {
            const { width } = slideRef.current.getBoundingClientRect();

            setActiveTab(item);
            slideRef.current.scrollTo({
                behavior: 'smooth',
                left: width * index
            })
        }
    }

    return (
        <section className="contact-us-section" id="faq">
            <div className="header-container">
                <SectionHeader
                    title="FREQUENTLY ASKED QUESTIONS"
                    subTitle="FAQ"
                    description={t("Have Question") + "?"}
                />
            </div>
            <div className="container">
                <Hide open={size < 1300}>
                    <div className="actions">
                        <div className="contact-btn">
                            <p className="title">{t("Download")}?</p>
                            <p className="sub-title">{t("We Will Answer As Soon As Possible")}!</p>

                            <RoundButton background="gradient" shadow="gradient" height="6rem">
                                <p className="label">{t("Contact US")}</p>
                            </RoundButton>
                        </div>
                    </div>
                </Hide>
                <div className="question-slide" ref={slideRef}>
                    <div className="questions">
                        {[
                            <AddReactionIcon className="W(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <CloudDownload className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <ShoppingCart className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <LiveTv className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />
                        ].map((icon, i) => (
                            <CustomAccordion
                                key={i}
                                icon={icon}
                                title={t(`section.faq.general.${i}.title`)}
                                description={t(`section.faq.general.${i}.description`)}
                            />
                        ))}


                        <CustomAccordion
                            icon={<Speed className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />}
                            title={t("section.faq.general.4.title")}
                            description={t("section.faq.general.4.description")}
                        >
                            <div className="D(f) Fld(c) Mt(2rem) ">
                                <div className="D(f) Ai(c) Mt(5px) Lh(.6) ">
                                    <p className="Fz(4rem) Mend(5px)">•</p>
                                    <p className="Fz(1.4rem)">{t("1MB for SD quality")}</p>
                                </div>
                                <div className="D(f) Ai(c) Lh(.6)">
                                    <p className="Fz(4rem) Mend(5px)">•</p>
                                    <p className="Fz(1.4rem)">{t("1.5MB for HD quality")}</p>
                                </div>
                                <div className="D(f) Ai(c) Lh(.6)">
                                    <p className="Fz(4rem) Mend(5px)">•</p>
                                    <p className="Fz(1.4rem)">{t("2.5MB for FHD quality")}</p>
                                </div>
                            </div>
                        </CustomAccordion>



                    </div>
                    <div className="questions">
                        <CustomAccordion
                            icon={<Payment className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />}
                            title={t("section.faq.payment.0.title")}
                        >
                            <div className="accordion-answer">
                                <p className="Mstart(4rem)">{t("section.faq.payment.0.description")}</p>
                                <div className="D(f) Ai(c) Mt(3rem)">
                                    <img className="H(3rem) Mstart(2rem)" src={cashplusImage} alt="cashplusImage" />
                                    <img className="H(4rem) Mstart(2rem)" src={sepaLogo} alt="sepalogo" />
                                    <img className="H(3rem) Mstart(2rem)" src={paypalLogo} alt="paypallogo" />
                                    <img className="H(3rem) Mstart(2rem)" src={bankLogo} alt="banklogo" />
                                    <img className="H(3rem) Mstart(2rem)" src={visaLogo} alt="visalogo" />
                                    <img className="H(3rem) Mstart(2rem)" src={mastercardLogo} alt="mastercard" />
                                    <img className="H(3rem) Mstart(2rem)" src={cmiLogo} alt="cmilogo" />
                                </div>
                            </div>
                        </CustomAccordion>


                        {[
                            <PanTool className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <Policy className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <MonetizationOn className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />
                        ].map((icon, i) => (
                            <CustomAccordion
                                icon={icon}
                                title={t(`section.faq.payment.${i + 1}.title`)}
                                description={t(`section.faq.payment.${i + 1}.description`)}
                            />

                        ))}

                    </div>
                    <div className="questions">
                        {[
                            <FiberNew className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <AddCircle className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <Group className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <FiberPin className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <ImportantDevices className="Fz(2.5rem)! Mend(2rem)  accordion-icon" />,
                            <ConnectIcon className="W(2.5rem)! Mend(2rem)  accordion-icon" />
                        ].map((icon, i) => (
                            <CustomAccordion
                                icon={icon}
                                title={t(`section.faq.application.${i}.title`)}
                                description={t(`section.faq.application.${i}.description`)}
                            />

                        ))}
                    </div>
                </div>
                <div className="shadow-divider"></div>
                <div className="actions Ov(h)  ">
                    <div className="tab-btns">
                        {tabData.map((item, i) => (
                            <Box marginTop="1rem">
                                <RoundButton className={clsx("W(100%) Jc(fs)!", item.key === activeTab && 'active')} onClick={() => handleSlide(i, item.key)} key={item.key} background={activeTab === item.key ? 'default' : 'none'} shadow={activeTab === item.key ? 'default' : 'none'} height="6rem">
                                    {item.icon}
                                    <p style={{ fontSize: '1.8rem', fontWeight: 500, color: activeTab === item.key ? '#fff' : 'var(--text-primary)' }}>{t(item.label)}</p>
                                </RoundButton>
                            </Box>
                        ))}
                    </div>

                    <div className="divider"></div>

                    <Hide open={size > 1300}>
                        <div className="contact-btn">
                            <p className="title">{t("Have")} <span className="gradient-text">{t("Questions")}?</span></p>
                            <p className="sub-title">{t("We Will Answer As Soon As Possible")}!</p>

                            <RoundButton background="gradient" shadow="gradient" height="6rem">
                                <a href="mailto:contact@bridgetv.africa">
                                    <p className="label">{t("Contact Us")}</p>
                                </a>
                            </RoundButton>
                        </div>
                    </Hide>
                </div>
            </div>
        </section>
    )
}

export default Contact;
