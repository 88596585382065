import React, { ReactElement } from 'react';
import RoundButton from '../round-button/RoundButton.component';
import './hero.style.scss';
import heroImage from '../../assets/images/heroimage.png';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '../../assets/icons/Phone.icon';
import ChromeIcon from '../../assets/icons/Chrome.icon';
import AmazonICon from '../../assets/icons/Amazon.icon';
import PlayStationIcon from '../../assets/icons/PlayStation.icon';
import XBoxIcon from '../../assets/icons/XBox.icon';
import SocialIcons from '../social-icons/SocialIcons.component';
import Hide from '../../molecules/Hide.mole';
import useSize from '../../hooks/size.hook';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupFormState } from '../popups/popup.atom';
import TextAnimation from '../../molecules/TextAnimation.mole';
import GifSection from '../gif-section/GifSection.component';
import FreeLink from '../../molecules/FreeLink.mole';
import { useTranslation } from "react-i18next";


interface Props {

}

function Hero({ }: Props): ReactElement {

    const size = useSize();
    const setPopupOpen = useSetRecoilState(popupFormState);
    const {t} = useTranslation();

    const handlePopupOpen = () => {
        setPopupOpen(true);
    }

    return (
        <section className="hero" id="home">

            <Hide open={size > 1000}>
                <div className="social-icons-container">
                    <SocialIcons width="4.5rem" background="default" direction="column" iconSize="2rem" />
                </div>
            </Hide>

            <div className="tag">
                <div className="bar"></div>
                <p className="label">{t("THE BRIDGE OF ENTERTAINMENTS")}</p>
            </div>

            <p className="main-title M">

                <TextAnimation delay={0}>
                   {t('No More')} 
                </TextAnimation>
                <span className="colored gradient-text">&nbsp;{t('Streaming')}</span><br />
                <span className="colored gradient-text">
                    <TextAnimation delay={800}>
                       {t('Service')} 
                    </TextAnimation>

                </span>
                &nbsp;{t('Needed')}

            </p>


            <GifSection />

            <p className="description Mt(-3rem)!">
                {t('section.hero.description')}
                <br />
                <span className="Fw(600)"> {t('section.hero.description-ii')}</span>
            </p>

            <RoundButton
                height="6rem"
                background="gradient"
                shadow="gradient"
                paddingx="6rem"
                // onClick={handlePopupOpen}

                className="cta-btn"
            >
                <FreeLink>
                    <p className="btn-text">{t("Try BridgeTV For Free")}</p>
                </FreeLink>
            </RoundButton>

            <div className="image-platforms">
                {/* <Hide open={size > 900}>
                    <div className="image-container">
                        <img className="hero-image" src={heroImage} alt="" />
                    </div>
                </Hide> */}

                <div className="slide-container">

                    <h2 className="C(#b1afb3) Mb(-3rem) Mt(3rem) Fw(500)">{t('We Are Everywhere')}</h2>

                    <div className="platforms">
                        <div className="container">
                            <AppleIcon className="icon" />
                            <p className="label">Apple</p>
                        </div>
                        <div className="container">
                            <AndroidIcon className="icon" />
                            <p className="label">AndroidTV</p>
                        </div>
                        <div className="container">
                            <p className="roku-icon">Roku</p>
                            <p className="label">Roku</p>
                        </div>
                        <div className="container">
                            <ChromeIcon className="icon" />
                            <p className="label">ChromeCast</p>
                        </div>
                        <div className="container">
                            <AmazonICon className="icon" />
                            <p className="label">Amazon</p>
                        </div>


                        <div className="container extra">
                            <AppleIcon className="icon" />
                            <p className="label">Apple</p>
                        </div>
                        <div className="container extra">
                            <AndroidIcon className="icon" />
                            <p className="label">AndroidTV</p>
                        </div>
                        <div className="container extra">
                            <p className="roku-icon">Roku</p>
                            <p className="label">Roku</p>
                        </div>
                        <div className="container extra">
                            <ChromeIcon className="icon" />
                            <p className="label">ChromeCast</p>
                        </div>
                        <div className="container extra">
                            <AmazonICon className="icon" />
                            <p className="label">Amazon</p>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;
