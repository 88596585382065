import React, { ReactElement } from 'react'

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    className?: string
}

function ChromeIcon(props: Props): ReactElement {
    return (
        <svg  viewBox="0 0 24 24"  {...props}>
            <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z" />
        </svg>
    )
}

export default ChromeIcon;
