import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {}

function TestPage({}: Props) {

  useEffect(()=>{
    window.location.href = "https://sowl.co/behKJr";
  },[])
  
  return null;
}

export default TestPage