import { makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import appLogo from '../../assets/images/logo.png';
import './app-logo.style.scss'

interface Props {
    color?: string
}



function AppLogo({ color = "var(--text-primary)" }: Props): ReactElement {


    return (

        <a href="#home" className="app-logo" >
            <img className="img" height="auto" src={appLogo} alt="App Logo" />
            <p className="title" style={{color: color}}>BRIDGETV</p>
        </a>

    )
}

export default AppLogo;
