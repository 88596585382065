import { useEffect, useRef } from "react";

export const useIsInit = () => {
    const mountRef = useRef(true);

    useEffect(() => {
        mountRef.current = false
    }, [])

    return mountRef.current;
}