import React, { ReactElement } from 'react'

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    className?: string,
    gradientId?: string
}

function SamsungIcon(props: Props): ReactElement {
    return (
        <svg viewBox="0 0 2078.27 304.3" {...props} fill={`url(#${props.gradientId})`}>
            <defs>
                <linearGradient id={props.gradientId} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#b8bcd8" />
                    <stop offset="1" stop-color="#b8bcd8" />
                </linearGradient>
            </defs>
            <path d="M1139.64,141.3s-20.22-12.5-32.72-17.34c0,0-38.7-17.94-46.38-26.47,0,0-15.08-14.22-6.25-30.73,0,0,3.7-10.8,19.64-10.8,0,0,20.75,1.14,20.75,18.2V98.22h74.37l-.13-35.44s5.72-58-87.6-60.58c0,0-73.4-4.82-94.45,34.71,0,0-8.26,8.82-8.26,39.53V98.63s-.84,25.6,12.23,42.1c0,0,7.39,10.82,25.6,22.75,0,0,37,19.91,59.17,31.31,0,0,22.4,12.79,19.46,33.14,0,0-1.83,20.89-23.47,20,0,0-19.77-.88-19.77-21.37V202.54H972.58v34.93s-2.22,66.8,99.61,66.8c0,0,97.3,2,101.84-68.55V207.28s3.43-43.22-34.39-66M758.9,10.51,734,166.57h-5.82L704.39,11.92H580.17l-6.23,282.59h73.55l.89-212.92h5.78l39,212.88h77.37L809,81.67h5.12l1.78,212.84h73.94l-8-284ZM321.07,11.69l-48.93,282.5h79.18l29-219h6.51l29,219H495L446,11.69ZM1982.52,137.41v41.67h20.31v44.74c0,21.86-18.69,22.26-18.69,22.26-22.72,0-21.86-20.77-21.86-20.77V70.58c0-15.94,20.17-16.8,20.17-16.8,19.35,0,19.48,19,19.48,19V96.32h74.55c2.43-45.92-6.72-57-6.72-57C2051.26-2.25,1981.3,0,1981.3,0c-107.5,0-95.57,82.49-95.57,82.49V232.12c2.31,77.36,108.25,69.5,110,69.44,47.12-5.22,61.35-20.2,61.35-20.2a45.81,45.81,0,0,0,16.47-23.2c3.75-7.85,4.69-32.85,4.69-32.85v-87.9Zm-247.11,46h-3.24l-75.65-172.8H1566v284h73.67l-4.41-172.77h3.29l78.38,172.77h87.76v-284h-74.6Zm-349.24,40.4s1.07,23.31-20.54,23.31c0,0-22.72,1.22-22.72-22.73l-.24-213.61h-80.8V222.67s-8.29,80.21,104.9,80.21c0,0,98.36,1.17,98.36-76.52V10.77h-79ZM167,141.3s-20.2-12.5-32.71-17.33c0,0-38.68-17.92-46.36-26.47,0,0-15.08-14.24-6.23-30.71,0,0,3.68-10.8,19.61-10.8,0,0,20.76,1.12,20.76,18.18V98.23h74.4l-.15-35.45s5.68-58-87.59-60.57a168.14,168.14,0,0,0-17.35.07s-56.5,3-76.71,34c-.12.23-.29.4-.4.61,0,0-8.25,8.81-8.25,39.53V98.64s-.86,25.61,12.23,42.1c0,0,7.39,10.82,25.6,22.75,0,0,37,19.91,59.16,31.31,0,0,22.42,12.76,19.45,33.14,0,0-1.83,20.89-23.45,20,0,0-19.78-.88-19.78-21.38V202.54H0v35s-2.21,66.79,99.61,66.79c0,0,97.27,2,101.85-68.54V207.33s3.4-43.27-34.43-66" transform="translate(0 0)" />
        </svg>
    )
}

export default SamsungIcon;