import { ButtonBase, Input } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from "react-i18next";
import TelegramIcon from '../../assets/icons/Telegram.icon';
import RoundButton from '../round-button/RoundButton.component';
import './subscribe.style.scss';

interface Props {

}

function Subscribe({ }: Props): ReactElement {

    const { t } = useTranslation();

    return (
        <section className="newsletter-subscribe-section">
            <div className="header-title">
                <p className="title">{t("Join Us")}</p>
                <p className="sub-title">{t("In Our Newsletter")}</p>
            </div>
            <div className="wraper">
                <input className="input-email" placeholder={t("Enter Your Email")+"..."} />
                <RoundButton background="gradient" shadow="gradient" className="subscribe-btn">
                    <p className="label">{t("Subscribe")}</p>
                    <TelegramIcon className="icon" />
                </RoundButton>
            </div>
        </section>
    )
}

export default Subscribe;
