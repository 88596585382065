import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import ukImage from './assets/images/uk.png';
import franceImage from './assets/images/franch.png';
import moroccoImage from './assets/images/morocco.png';
import bdImage from './assets/images/bangladesh.png';
import { Keyable } from "./types";

export interface LanguageType {
    native: string,
    label: string,
    image: string
}

export const supportedLanguage: Keyable<LanguageType> = {
    en: {
        native: 'English',
        label: 'ENG',
        image: ukImage
    },
    fr: {
        native: 'français',
        label: 'FRA',
        image: franceImage
    },
    ar: {
        native: 'عربى',
        label: 'ARA',
        image: moroccoImage
    },
    bn: {
        native: 'বাংলা',
        label: 'BAN',
        image: bdImage
    }
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next).init({
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        supportedLngs: Object.keys(supportedLanguage),
        fallbackLng: 'en',
    });