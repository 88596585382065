import { ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

import './round-button.style.scss';

interface Props {
    children: ReactNode,
    endIcon?: ReactElement,
    startIocn?: ReactElement,
    background?: 'default' | 'none' | 'gradient',
    shadow?: 'default' | 'none' | 'gradient',
    bdrs?: string,
    height?: string | number,
    width?: string | number,
    paddingx?: string | number,
    style?: React.CSSProperties,
    onClick?: (event: React.MouseEvent<HTMLElement>) => void,
    className?: string,
    disabled?: boolean
}

function RoundButton({ disabled,background = "default", shadow = "default", ...props }: Props): ReactElement {
    return (
        <div className="round-button">
            <ButtonBase
                disabled={disabled}
                className={clsx('btn-base', `bg-${background}`, `sh-${shadow}`, props.className)}
                style={{ height: props.height, width: props.width, paddingLeft: props.paddingx, paddingRight: props.paddingx, borderRadius: props.bdrs, ...props.style }}
                onClick={props.onClick}
            >

                {props.children}
            </ButtonBase>
        </div>
    )
}

export default RoundButton
