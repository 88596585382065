import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import RoundButton from '../../components/round-button/RoundButton.component'
import FreeLink from '../../molecules/FreeLink.mole';
import party from "party-js";
import SuccessIcon from '../../molecules/success-animation/Success.icon';
import AppLogo from '../../molecules/app-logo/AppLogo.mole';



type Props = {}

function Thanks({ }: Props) {

    const element = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        element.current && party.confetti(element.current, {
            count: party.variation.range(20, 60),
        });
    }, [])

    return (
        <div className="">
            <div className="Pos(a) Start(50%) TranslateX(-50%) T(3rem)">
                <AppLogo />
            </div>
            <div className="Mih(100vh) D(f) Jc(c) Ai(c)">
                <div className="D(f) Fxd(c) Ai(c)" ref={element}>
                    <SuccessIcon height="150px" width="150px" />
                    <h1 className="Fz(6rem) gradient-text" >Félicitations!</h1>
                    <div className="Fz(1.8rem) Maw(50rem) Ta(c) Mb(5rem) C(primary) Mt(2rem)">
                        <p>
                            Merci pour l’intérêt démontré à BRIDGETV.
                        </p>
                        <p className="Mt(1rem)">
                            Vous allez recevoir votre code gratuit <span className="gradient-text Fw(b)">par e-mail</span> dans quelques minutes (vérifiez spam aussi).
                        </p>
                        <p className="Mt(1rem)">
                            Si vous avez des questions n’hésitez pas à nous contacter à  <a href="mailto:contact@bridgetv.africa" className="Fw(b) gradient-text">contact@bridgetv.africa</a>
                        </p>
                    </div>

                    <Link to="/">
                        <RoundButton
                            height="5rem"
                            background="gradient"
                            shadow="gradient"
                            paddingx="3rem"
                            // onClick={handlePopupOpen}

                            className="cta-btn"
                        >
                            <span className="C(white) Fz(2rem)">Revenir</span>
                        </RoundButton>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Thanks