import { ButtonBase, makeStyles } from '@material-ui/core'
import { Done } from '@material-ui/icons';
import React, { ReactElement, useState } from 'react'
import Hide from './Hide.mole';


interface Props {
    defaultChecked?: boolean
}

const useStyles = makeStyles({
    root: {
        width: '2rem',
        height: '2rem',
        borderRadius: '.7rem',
        border: '1px solid var(--text-primary)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        visibility: 'hidden',
        display: 'none'
    },
    icon: {
        fill: 'var(--text-primary)',
        fontSize: '1.4rem !important'
    }
})

function CheckBox({ defaultChecked=false }: Props): ReactElement {

    const [checked, setChecked] = useState(defaultChecked);
    const classes = useStyles();

    const handleChange = () => {
        setChecked(pre=>!pre);
    }

    return (
        <>
            <input className={classes.input} type="checkbox" checked={checked} onChange={handleChange} />
            <ButtonBase className={classes.root} onClick={handleChange}>
                <Hide
                    open={checked}
                >
                    <Done className={classes.icon} />
                </Hide>
            </ButtonBase>
        </>
    )
}

export default CheckBox
