import React, { ReactElement } from 'react'

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    className?: string
}

function DevicesIcon(props: Props): ReactElement {
    return (
        <svg viewBox="0 0 1024.000000 1024.000000" {...props}>

            <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
                stroke="none">
                <path d="M3280 7348 c-50 -14 -88 -44 -110 -88 -19 -38 -20 -61 -20 -625 l0
-585 170 0 170 0 2 478 3 477 2202 3 2203 2 2 -537 3 -538 170 0 170 0 3 625
c2 445 0 637 -8 665 -13 45 -50 88 -95 113 -28 16 -214 17 -2430 19 -1573 1
-2412 -2 -2435 -9z"/>
                <path d="M658 5850 c-49 -26 -76 -67 -88 -132 -6 -33 -9 -457 -8 -1133 l3
-1080 1738 -3 1737 -2 -2 1122 c-3 1256 3 1156 -75 1215 l-36 28 -1616 2
c-1583 2 -1617 2 -1653 -17z m3027 -1165 l0 -840 -1385 0 -1385 0 -3 830 c-1
457 0 836 3 843 3 10 286 12 1387 10 l1383 -3 0 -840z"/>
                <path d="M6816 5745 c-51 -18 -104 -71 -116 -116 -7 -24 -9 -492 -8 -1321 l3
-1283 23 -37 c13 -20 40 -47 60 -60 l37 -23 985 -3 c882 -2 989 -1 1027 13 47
18 99 71 110 113 5 15 8 605 8 1312 0 1195 -1 1287 -17 1316 -23 43 -71 81
-116 94 -24 6 -366 10 -997 10 -791 -1 -967 -3 -999 -15z m1784 -1415 l0
-1080 -780 0 -780 0 0 1080 0 1080 780 0 780 0 0 -1080z m-736 -1201 c52 -41
19 -129 -48 -129 -26 0 -66 34 -67 58 -3 34 3 47 27 70 31 27 54 28 88 1z"/>
                <path d="M9239 4518 c-18 -13 -44 -43 -56 -68 l-23 -44 0 -686 0 -686 23 -44
c16 -33 34 -51 67 -68 45 -22 47 -23 412 -20 354 3 368 4 395 24 15 11 38 36
51 54 l22 33 0 704 c0 779 2 748 -64 796 -31 22 -36 22 -412 25 l-380 3 -35
-23z m569 -110 c4 -36 -11 -39 -177 -36 -112 3 -136 6 -144 20 -8 12 -7 21 3
32 11 14 35 16 164 14 l151 -3 3 -27z m192 -668 l0 -530 -350 0 -350 0 0 530
0 530 350 0 350 0 0 -530z m-310 -640 c40 -40 12 -102 -46 -102 -32 0 -54 25
-54 61 0 55 61 80 100 41z"/>
                <path d="M4217 4183 c-4 -3 -7 -82 -7 -175 l0 -168 1150 0 1150 0 0 175 0 175
-1143 0 c-629 0 -1147 -3 -1150 -7z"/>
                <path d="M5197 3653 c-2 -5 -8 -26 -12 -48 -13 -74 -67 -229 -108 -310 -54
-108 -89 -162 -186 -283 -45 -57 -81 -106 -78 -108 2 -2 383 -3 846 -2 l842 3
-57 70 c-177 214 -271 394 -319 608 l-17 77 -453 0 c-250 0 -456 -3 -458 -7z"/>
                <path d="M72 3182 c3 -131 4 -141 30 -180 15 -22 49 -54 75 -69 l48 -28 2075
0 2075 0 40 22 c94 50 115 99 115 268 l0 125 -2231 0 -2231 0 4 -138z"/>
            </g>
        </svg>

    )
}

export default DevicesIcon;

