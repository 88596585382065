import React, { ReactElement } from 'react'

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    className?: string,
    gradientId?: string
}

function AndroidIcon(props: Props): ReactElement {
    return (
        <svg viewBox="0 0 35 39" {...props} fill={`url(#${props.gradientId})`}>
            <defs>
                <linearGradient id={props.gradientId} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#b8bcd8" />
                    <stop offset="1" stop-color="#b8bcd8" />
                </linearGradient>
            </defs>
            <path d="M4.26 13.06L4.17 13.06C2.97 13.06 2 14.04 2 15.23L2 24.64C2 25.84 2.97 26.81 4.17 26.81L4.26 26.81C5.45 26.81 6.43 25.83 6.43 24.64L6.43 15.23C6.43 14.04 5.45 13.06 4.26 13.06ZM7.46 28.28C7.46 29.37 8.36 30.27 9.46 30.27L11.58 30.27L11.58 35.35C11.58 36.55 12.56 37.52 13.75 37.52L13.84 37.52C15.04 37.52 16.01 36.55 16.01 35.35L16.01 30.27L18.99 30.27L18.99 35.35C18.99 36.55 19.97 37.52 21.16 37.52L21.25 37.52C22.44 37.52 23.42 36.55 23.42 35.35L23.42 30.27L25.54 30.27C26.64 30.27 27.53 29.37 27.53 28.28L27.53 13.41L7.46 13.41L7.46 28.28ZM22.5 4.39L24.19 1.78C24.3 1.62 24.25 1.4 24.08 1.29C23.92 1.18 23.69 1.23 23.59 1.39L21.83 4.09C20.52 3.55 19.05 3.25 17.5 3.25C15.95 3.25 14.48 3.55 13.17 4.09L11.42 1.39C11.31 1.23 11.08 1.18 10.92 1.29C10.75 1.4 10.7 1.62 10.81 1.78L12.5 4.39C9.45 5.88 7.4 8.69 7.4 11.92C7.4 12.12 7.41 12.31 7.43 12.51L27.58 12.51C27.59 12.31 27.6 12.12 27.6 11.92C27.6 8.69 25.55 5.88 22.5 4.39ZM12.83 9.05C12.29 9.05 11.86 8.62 11.86 8.09C11.86 7.55 12.29 7.12 12.83 7.12C13.37 7.12 13.8 7.55 13.8 8.09C13.8 8.62 13.36 9.05 12.83 9.05ZM22.17 9.05C21.64 9.05 21.2 8.62 21.2 8.09C21.2 7.55 21.64 7.12 22.17 7.12C22.71 7.12 23.14 7.55 23.14 8.09C23.14 8.62 22.71 9.05 22.17 9.05ZM30.83 13.06L30.74 13.06C29.55 13.06 28.57 14.04 28.57 15.23L28.57 24.64C28.57 25.84 29.55 26.81 30.74 26.81L30.83 26.81C32.03 26.81 33 25.83 33 24.64L33 15.23C33 14.04 32.03 13.06 30.83 13.06Z" />
        </svg>
    )
}

export default AndroidIcon;