import { ThemeProvider } from '@material-ui/core'
import React from 'react'
import Contact from '../../components/contact/Contact.component'
import Download from '../../components/download/Download.component'
import Features from '../../components/features/Features.component'
import Footer from '../../components/footer/Footer.compoent'
import Header from '../../components/header/Header.component'
import Hero from '../../components/hero/Hero.component'
import Popup from '../../components/popups/Popup.component'
import Pricing from '../../components/pricing/Pricing.component'
import Subscribe from '../../components/subscribe/Subscribe.component'
import Divider from '../../molecules/Divider.mole'
import { theme } from '../../theme'

type Props = {}

function Home({ }: Props) {
    return (
        <React.Fragment>

            <main id="#top">
                <Header />
                <Hero />
                <Features />
                <Pricing />
                <Download />
                <div className="Mt(3rem)">
                    <Divider />
                </div>
                <Contact />
                <Subscribe />
                <Footer />
            </main>
            <Popup />
        </React.Fragment>
    )
}

export default Home