import { ThemeProvider } from '@material-ui/styles';
import { RecoilRoot } from 'recoil';
import { theme } from './theme';
import './App.css'
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home.page';
import Thanks from './pages/thanks/Thanks.page';
import TestPage from './pages/test/Test.page';
import { Suspense } from "react";

function App() {

  return (
    <Suspense fallback>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="/test" element={<TestPage />} />
          </Routes>
        </ThemeProvider>
      </RecoilRoot>
    </Suspense>
  );
}

export default App;
