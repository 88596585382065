import React, { ReactNode } from 'react'

type Props = {
    children: ReactNode
}

function FreeLink({children}: Props) {
  return (
    <a href="https://sowl.co/behKJr" rel="noreferrer" target="blank">
        {children}
    </a>
  )
}

export default FreeLink