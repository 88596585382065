import React, { ReactElement } from 'react'

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    className?: string
}

function ChromeIcon(props: Props): ReactElement {
    return (
        <svg viewBox="0 0 40 39" {...props}>
            <path d="M20.23 13.37C23.57 13.37 26.29 16.08 26.29 19.43C26.29 22.77 23.57 25.48 20.23 25.48C16.89 25.48 14.18 22.77 14.18 19.43C14.18 16.08 16.89 13.37 20.23 13.37ZM12.23 17.68C13.03 14 16.32 11.24 20.23 11.24L36.53 11.24C35.67 9.52 34.52 7.93 33.12 6.53C29.68 3.09 25.1 1.19 20.23 1.19C15.36 1.19 10.78 3.09 7.34 6.53C7.01 6.87 6.69 7.21 6.39 7.56L12.23 17.68ZM22.72 27.23C21.93 27.48 21.1 27.62 20.23 27.62C17.19 27.62 14.53 25.95 13.12 23.48C13.11 23.47 13.1 23.45 13.09 23.43L4.99 9.41C3.04 12.36 2 15.81 2 19.43C2 24.3 3.9 28.88 7.34 32.32C9.98 34.96 13.28 36.69 16.87 37.35L22.72 27.23ZM25.74 13.37C27.39 14.87 28.42 17.03 28.42 19.43C28.42 20.93 28.01 22.35 27.3 23.56C27.29 23.57 27.29 23.59 27.28 23.6L19.18 37.63C19.53 37.65 19.88 37.66 20.23 37.66C25.1 37.66 29.68 35.76 33.12 32.32C36.57 28.88 38.46 24.3 38.46 19.43C38.46 17.33 38.11 15.29 37.44 13.37L25.74 13.37Z" />
        </svg>
    )
}

export default ChromeIcon;