import React, { ReactElement } from 'react'

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    className?: string
}

function ArrowIndicatorIcon(props: Props): ReactElement {
    return (
        <svg {...props} viewBox="0 0 130 48">
            <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g  transform="translate(116.239340, 5.500000)" stroke="#01B289" stroke-linecap="round">
                    <path d="M2.76065965,41 C9.69999903,35.0184431 13.1696687,28.5622199 13.1696687,21.6313304 C13.1696687,11.2349961 9.9612718,5.84820074 2.76065965,0"  strokeDasharray="5"></path>
                    <polyline id="Path-34" stroke-linejoin="round" transform="translate(3.667104, 40.132565) rotate(43.000000) translate(-3.667104, -40.132565) " points="1 38.7898586 3.66533404 41.4752713 6.33420864 38.8315177"></polyline>
                </g>
            </g>
        </svg>
    )
}

export default ArrowIndicatorIcon;