import clsx from 'clsx';
import React, { ReactElement, useRef } from 'react';
import './slide.style.scss';

interface Props {
    children: React.ReactNode,
    className?: string,
    width?: string
}

export const SlideContext = React.createContext<any>({});

function Slide({ children,className,width }: Props): ReactElement {

    const stateRef = useRef(null);

    return (
        <SlideContext.Provider value={{state: stateRef,width}}>
            <div className={clsx('slide-container--flex-slider',className)}>
                {children}
            </div>
        </SlideContext.Provider>
    )
}
export default Slide;
