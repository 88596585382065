import { AppBar, Box, ButtonBase, ClickAwayListener, createStyles, Fade, Grid, makeStyles, Popper } from '@material-ui/core';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import AppLogo from '../../molecules/app-logo/AppLogo.mole';
import Hide from '../../molecules/Hide.mole';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './header.style.scss';
import clsx from 'clsx';
import RoundButton from '../round-button/RoundButton.component';
import { countryImage } from '../../importer';
import MenuIcon from '@material-ui/icons/Menu';
import useSize from '../../hooks/size.hook';
import CloseIcon from '@material-ui/icons/Close';
import SocialIcons from '../social-icons/SocialIcons.component';
import { useSetRecoilState } from 'recoil';
import { popupFormState } from '../popups/popup.atom';
import CountrySelect from '../country-select/CountrySelect.component';

import ScrollProgress from '../scroll-progress/ScrollProgress.component';
import DropDown, { OptionItemType } from '../../molecules/drop-down/DropDown.component';
import FreeLink from '../../molecules/FreeLink.mole';
import { useTranslation } from "react-i18next";
import { supportedLanguage } from "../../i18next";


interface Props {

}

interface MenuItem {
    title: string,
    active: boolean,
    onClick: () => void
}

const data: OptionItemType[] = Object.keys(supportedLanguage).map((key: string) => {
    const item = supportedLanguage[key];
    return {
        image: item.image,
        key: key,
        label: item.native,
    }
})

const EndItems = () => {

    const size = useSize();
    const setPopupOpen = useSetRecoilState(popupFormState);
    const { i18n, t } = useTranslation();


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setPopupOpen(true);
    }

    const language = supportedLanguage[i18n.language];

    return (
        <div className="end-items">
            <div className="Miw(10rem)">
                <DropDown
                    options={data}
                    onClick={(item) => i18n.changeLanguage(item.key)}
                    defaultOption={{ image: language.image, key: i18n.language, label: language.native }}
                />
            </div>
            <div className="divider"></div>
            <Hide open={size > 1208}>
                <RoundButton background="gradient" shadow="gradient" height="4rem" >
                    <FreeLink>
                        <p className="label" style={{ width: 'max-content' }} >{t('Try For Free')}</p>
                    </FreeLink>
                </RoundButton>
            </Hide>
        </div>
    )
}

const MenuItem = ({ title }: { title: string }) => {
    const { t } = useTranslation();
    return (
        <li className="nav-item" >
            <a href={`#${title.toLocaleLowerCase()}`} className="label" >{t(title)}</a>
        </li>
    )
}

const Menu = () => {

    const menuItems = ['Home', 'Features', 'Price', 'Download', 'FAQ'];

    return (
        <nav className="nav-big">
            <ul>
                {menuItems.map((item, i) => (
                    <MenuItem key={`header-menu-${item}-${i}`} title={item} />
                ))}
            </ul>
        </nav>
    )
}



const MenuSmall = () => {

    const [open, setOpen] = useState(false);
    const [openAnim, setOpenAnim] = useState(true)

    const handleClose = () => {
        setOpenAnim(false);

        setTimeout(() => {
            setOpen(false);
            setOpenAnim(true);
        }, 300);
    }

    return (
        <div className={clsx('nav-small', !openAnim && 'nav-small-anim')}>
            <ButtonBase className="menu-button" onClick={() => setOpen(true)}>
                <MenuIcon className="icon" />
            </ButtonBase>

            <Hide open={open}>
                <div className="nav-menu">
                    <div className="circle-wraper" >
                        <div className={`circle--nav ${openAnim ? 'circle-out' : 'circle-in'}`}></div>
                    </div>

                    <nav >
                        <div className="close-icon" onClick={handleClose}>
                            <CloseIcon className="icon" />
                        </div>
                        <ClickAwayListener onClickAway={handleClose}>
                            <ul>
                                {['Home', 'Features', 'Download', 'Price', 'FAQ'].map((item, i) => (
                                    <li onClick={handleClose} key={`${item}--${i}`}><a href={`#${item.toLowerCase()}`} >{item}</a></li>
                                ))}
                                <Hide open={openAnim}>
                                    <SocialIcons />
                                </Hide>
                            </ul>
                        </ClickAwayListener>
                    </nav>

                </div>
            </Hide>
        </div>
    )
}

function Header({ }: Props): ReactElement {

    const size = useSize();
    const headerRef = useRef<HTMLDivElement | null>(null);
    const [headerHeight, setHeight] = useState<number | undefined>();
    const tran = useTranslation();
    console.log(tran)

    useEffect(() => {
        window.addEventListener('scroll', () => {

            if (!headerRef.current) return;

            const { height } = headerRef.current.getBoundingClientRect();

            if (window.pageYOffset > height) {
                headerRef.current.classList.add('sticky');
                setHeight(headerRef.current.getBoundingClientRect().height)
            } else {
                headerRef.current.classList.remove('sticky');
            }
        });
    }, [])

    const handleHeaderRef = (ref: HTMLDivElement | null) => {
        setHeight(ref?.getBoundingClientRect().height);
        headerRef.current = ref;
    }

    return (
        <div className="wraper" ref={handleHeaderRef}>

            <header className="header" >
                <Hide open={size < 950}>
                    <MenuSmall />
                </Hide>
                <div className="logo-container">
                    <AppLogo />
                </div>
                <Hide open={size > 950}>
                    <Menu />
                </Hide>
                <EndItems />
            </header>
            <ScrollProgress headerHeight={headerHeight} />
        </div>
    )
}

export default Header;
