import clsx from 'clsx';
import React, { createContext, ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { SlideContext } from './Slide.component';

interface Props {
    children: React.ReactNode,
    className?: string
}

export const BtnsContext = createContext<any>({});

function SlideBtns({ children, className }: Props): ReactElement {

    const { state, width } = useContext(SlideContext);
    const [index, setIndex] = useState(0);




    const handleNext = () => {
        if (index === state.current.data.length - 1) {
            setIndex(0);
        } else {
            setIndex(pre => pre + 1)
        }
    }

    const handlePrev = () => {
        if (index !== 0) {
            setIndex(pre => pre - 1)
        }
    }

    useEffect(() => {

        state.current.index = index;

        if (state.current) {
            const el: HTMLDivElement = state.current.element;
            const item = state.current.data[index];

            if (el && item) {
                // if (!width) {
                    el.style.width = `${item.width}px`;
                // }
                el.scrollTo({
                    behavior: 'smooth',
                    left: item.left
                });
            }
        }

    }, [index])

    return (
        <BtnsContext.Provider value={{ next: handleNext, prev: handlePrev, index, setIndex }}>
            <div className={clsx('slide-btns', className)}>
                {children}
            </div>
        </BtnsContext.Provider>
    )
}

export default SlideBtns;
